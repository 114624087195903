/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/nunito-v24-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/nunito-v24-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: optional;
}
/* nunito-600 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('../fonts/nunito-v24-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/nunito-v24-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: optional;
}
/* nunito-700 - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('../fonts/nunito-v24-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/nunito-v24-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: optional;
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// jQuery
@import '~jquery-ui/themes/base/all.css';

$fa-font-path:        "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
